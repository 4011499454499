import http from '@/utils/http'
// 获取客户管理数据列表分页
export const getRelaPge = p => http.post('/unibu/proj/relaPge', p)

// 新增客户信息
export const addRela = p => http.post('/unibu/proj/addRela', p)

// 新增客户信息
export const modRela = p => http.post('/unibu/proj/modRela', p)

// 删除客户信息
export const delRela = p => http.post('/unibu/proj/delRela', p)
