<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="单位类型:" prop="vehiType">
            <DataSelect v-model="searchModel.relaType" dictCode="base_rela_type"></DataSelect>
          </el-form-item>
          <el-form-item label="单位名称:" prop="firm">
            <el-input v-model="searchModel.firm"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="customerMan"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :align="allAlign"
          :data="tableData">
          <template #buttons>
            <el-button
              type="primary"
              v-auth="'销售管理_客户管理_新增'"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;addRow()"
            >新增往来单位
            </el-button
            >
          </template>
          <vxe-table-column type="seq" title="序号" width="60"/>
          <!--   <vxe-table-column field="projNum" title="编号" show-overflow="title" min-width="135" show-header-overflow="title"/>-->
          <vxe-table-column field="firm" title="单位名称" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="relaTypeName" title="单位类型" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="address" title="地址" show-overflow="title" min-width="80"
                            show-header-overflow="title"/>
          <vxe-table-column field="linkName" title="联系人-姓名" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="linkPhone" title="联系人-手机号" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)"
                         v-auth="'销售管理_客户管理_修改'" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)"
                         v-auth="'销售管理_客户管理_删除'" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <el-dialog :title="dialogEditTitle"
                 :visible.sync="dialogEdit"
                 :v-if="dialogEdit"
                 :append-to-body="true"
                 width="850px"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="160px" :model="cusEditModel"
                 :rules="rules"
                 style="margin-top: 20px">
          <el-form-item label="单位名称:" prop="firm">
            <el-input v-model="cusEditModel.firm"></el-input>
          </el-form-item>
          <el-form-item label="单位类型:" prop="relaType">
            <DataSelect v-model="cusEditModel.relaType" dictCode="base_rela_type"></DataSelect>
          </el-form-item>
          <el-form-item label="单位代码:" prop="firmCode">
            <el-input
              v-model="cusEditModel.firmCode"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="企业统一信用码:" prop="uniteCredit">
            <el-input
              v-model="cusEditModel.uniteCredit"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人-姓名:" prop="linkName">
            <el-input
              v-model="cusEditModel.linkName"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人-身份证:" prop="linkCard">
            <el-input
              v-model="cusEditModel.linkCard"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人-手机:" prop="linkPhone">
            <el-input
              v-model="cusEditModel.linkPhone"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="地址:">
            <el-input
              v-model="cusEditModel.address"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitBusEdit()" size="mini">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </page-container>
</template>

<script>
import { getRelaPge, addRela, modRela, delRela } from '@/apis/saleManage/saleMan'
import XEUtils from 'xe-utils'
import DataSelect from '../../components/Dictionary/DataSelect.vue'
export default {
  name: 'customerMan',
  data () {
    return {
      editMode: 'add',
      cusEditModel: {
        relaId: '',
        address: '',
        all: true,
        firm: '',
        firmCode: '',
        linkCard: '',
        linkName: '',
        linkPhone: '',
        relaType: '',
        uniteCredit: ''
      },
      rules: {
        firm: [
          { required: true, message: '单位名称为必填选项', trigger: 'change' }
        ],
        linkName: [
          { required: true, message: '请填写联系人', trigger: 'change' }
        ],
        linkPhone: [
          { required: true, message: '请填写联系方式', trigger: 'change' }
        ]
      },
      vehiTypeOptions: {
        1: '泵车',
        2: '混凝土搅拌车',
        3: '散装水泥车',
        4: '预拌砂浆车'
      },
      dialogEditTitle: '新增车辆',
      dialogEdit: false,
      searchModel: {
        firm: '',
        relaType: ''
      },
      ownTypeOptions: {
        1: '自备车',
        2: '外租车'
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      companyList: [],
      compListOptions: {}
    }
  },
  methods: {
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () {
      this.loading = true
      getRelaPge({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          this.tableData = data.rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    },
    addRow () {
      this.editMode = 'add'
      this.dialogEditTitle = '新增往来单位'
      this.cusEditModel = {
        relaId: '',
        address: '',
        all: true,
        firm: '',
        firmCode: '',
        linkCard: '',
        linkName: '',
        linkPhone: '',
        relaType: '',
        uniteCredit: ''
      }
    },
    editRow (row) {
      this.editMode = 'edit'
      //  this.modifyDetailRow = row
      this.dialogEditTitle = '修改车辆'
      const rowData = {}
      XEUtils.objectEach(this.cusEditModel, (item, key) => {
        rowData[key] = row[key] ? row[key].toString() : ''
      })
      this.cusEditModel = Object.assign({}, rowData)
      this.dialogEdit = true
    },
    deleteRow (row) {
      this.$confirm('确定删除该往来单位信息?', '删除往来单位', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delRela({
          relaId: row.relaId
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {
    },
    onSubmitBusEdit () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.editMode == 'add') {
            addRela({ ...this.cusEditModel }).then(res => {
              if (res.code === 200) {
                this.$message.success('添加成功！')
                this.dialogEdit = false
                this.refreshData()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            modRela({ ...this.cusEditModel }).then(res => {
              if (res.code === 200) {
                this.$message.success('修改成功！')
                this.dialogEdit = false
                this.refreshData()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    }
  },
  mounted () {
    this.refreshData()
  },
  components: { DataSelect }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}

</style>
